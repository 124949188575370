/**
 * The state of a file while/after it has been uploaded by a user.
 * The file is either being processed, or processing has completed and it has been assigned a pass/fail.
 * If it has failed file processing, the specific reason is assigned.
 */
export enum FileState {
    PROCESSING = 'PROCESSING',
    PASSED = 'PASSED',
    FAILED_SIZE = 'FAILED_SIZE',
    FAILED_MALWARE = 'FAILED_MALWARE',
    FAILED_TYPE = 'FAILED_TYPE',
    FAILED_NAME = 'FAILED_NAME',
}

/**
 * A file that was previously uploaded by a user and exists in S3.
 */
export interface UploadedFile {
    file_id: string;
    filename: string;
    file_state: FileState;
}

/**
 * A file selected by the user to be uploaded to S3, pending a successful request to the backend.
 */
export interface FileToBeUploaded {
    filename: string;
    file_id: string;
}

/**
 * A standardized set of updates to be applied to an existing entity with associated files.
 *
 * Updates will be interpreted as follows:
 * - If a file's ID is present in `existing_files_to_delete`, it will be removed from the entity. If this was the only reference to the file in S3, the file itself will also be deleted from S3.
 * - Every file in `new_files` will be associated with the entity; the statuses of the new files will be set to PROCESSING, with the expectation that the frontend will upload the files to S3.
 */
export interface FileUpdates {
    existing_files_to_delete?: string[];
    new_files?: FileToBeUploaded[];
}

/**
 * The result of requesting a file directly from the backend (as opposed to downloading it from S3 via a signed URL).
 */
export interface FileDownloadResponse {
    file_content: string;
    file_name: string;
}

/**
 * A request to upload a file to S3. This request is sent to the backend, which responds with a `SignedUploadResponse`.
 */
export interface SignedUploadRequest {
    file_name: string;
    file_id?: string;
}

/**
 * The response from the backend to a request to upload to S3. These fields are used to upload to S3 via the signed `url`, which grants temporary access to upload a specific file.
 */
export interface SignedUploadResponse {
    file_id: string;
    url: string;
    fields: { string: string }; // TODO: this is not accurate.
}

/**
 * A request to download a file from S3. This request is sent to the backend, which responds with a `SignedDownloadResponse`.
 */
export interface SignedDownloadRequest {
    file_id: string;
}

/**
 * The response from the backend to a request to download from S3. `url` is a signed URL that grants temporary access to download a specific file from S3.
 */
export interface SignedDownloadResponse {
    url: string;
}
