import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { IconButton } from 'Components/Buttons/IconButton';
import { Text } from 'Components/Text/Text';

import styles from './GridAndTable.module.css';

enum View {
    LIST = 'List',
    GRID = 'Grid',
}

/**
 * @param filters           - Optional filters that are placed above the grid/table view in the body of the page.
 * @param cards             - Content cards that form the grid in the body of the page. Uses can toggle between this and the table view.
 * @param table             - A table that represents the content of the page. Users can toggle between this and the grid view.
 * @param noContentMessage  - A message to display when there is no content to display (e.g., there are no cards and the table is empty). Only provide this when there is no content, since the conditional will select this over the cards/table if it has a value.
 */
export interface GridAndTableProps {
    filters?: React.ReactNode;
    cards: React.ReactNode[];
    table: React.ReactNode;
    noContentMessage?: string;
}

/**
 * A component that displays a grid of cards and a table, allowing users to toggle between the two views.
 */
export const GridAndTable = (props: GridAndTableProps): JSX.Element => {
    const [activeView, setActiveView] = useState<View>(View.GRID);

    return (
        <div className={styles.gridAndTable}>
            {props.noContentMessage ? (
                <Text>{props.noContentMessage}</Text>
            ) : (
                <>
                    <div className={styles.filtersAndSwitchView}>
                        <div className={styles.filters}>{props.filters}</div>
                        <div className={styles.switchView}>
                            <IconButton aria-label="Switch to Grid View" onClick={() => setActiveView(View.GRID)} fontAwesomeImage={faThLarge} disabled={activeView === View.GRID} />
                            <IconButton aria-label="Switch to List View" onClick={() => setActiveView(View.LIST)} fontAwesomeImage={faBars} disabled={activeView === View.LIST} />
                        </div>
                    </div>
                    {activeView === View.GRID ? <div className={styles.grid}>{props.cards}</div> : <div className={styles.table}>{props.table}</div>}
                </>
            )}
        </div>
    );
};
